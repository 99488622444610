import React from "react";
import { ReactComponent as DissonanceLogo } from "../../assets/dissonance-logo.svg";
import instagramLogo from "../../assets/social/instagram-logo.png";
import spotifyLogo from "../../assets/social/spotify-logo.png";
import styles from "./WorkInProgress.module.css";

const INSTAGRAM_LINK = "https://www.instagram.com/we_are_dissonance/";
const SPOTIFY_LINK =
  "https://open.spotify.com/artist/3oNkwD14X2CdZ8eND5rBTR?si=xbxjEwy1SoasYPBd5gcVrQ";

const WorkInProgress = () => {
  return (
    <div className={styles.container}>
      <div className={styles.backgroundImage}>
        <div className={styles.wipContainer}>
          <DissonanceLogo />
          <div className={styles.title}>Something's coming</div>
          <div className={styles.logoContainer}>
            <img
              className={styles.socialLogo}
              src={instagramLogo}
              alt={"White Instagram logo"}
              onClick={() => (window.location.href = INSTAGRAM_LINK)}
            />
            <img
              className={styles.socialLogo}
              src={spotifyLogo}
              alt={"White Spotify logo"}
              onClick={() => (window.location.href = SPOTIFY_LINK)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkInProgress;
